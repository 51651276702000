.lead {
    background: var(--main-color) linear-gradient(90deg, var(--main-gradient-color)) no-repeat center / cover;
    color: rgb(255 255 255 / 0.9);

    &__columns {
        display: grid;
        gap: var(--indent-block-large);
        grid-template-columns: 1.1fr 1fr;

        @include media(lg, max) {
            grid-template-columns: 1fr;
        }
    }

    // &__column {}

    &__image {
        @extend .corner-rounded_size_lg;
    }

    &__subtitle {
        margin: 0;
        font-size: clamp(0.875rem, 0.6rem + 1.375vw, 2.25rem);
    }

    &__title {
        margin: 0;
        display: block;
        color: #fff;
        font-family: var(--heading-font-family);
        font-size: var(--title-lead-font-size);
        font-weight: normal;
    }

    &__main-text {
        margin-block-end: clamp(1.5em, 5vw, 4em);
        font-size: var(--text-large-font-size);

        p {
            margin-block: 1em;
        }
    }
}
