.gallery {
    &__card-deck {
        gap: var(--indent-block-medium);
        @include media(xl) {
            grid-template-columns: 6.15fr 6.15fr 4.5fr;
            grid-auto-rows: 350px;
            li:nth-child(6n + 1) {
                grid-area: 1/1/2/2;
            }
            li:nth-child(6n + 2) {
                grid-area: 1/2/3/3;
            }
            li:nth-child(6n + 3) {
                grid-area: 1/3/2/4;
            }
            li:nth-child(6n + 4) {
                grid-area: 2/1/3/2;
            }
            li:nth-child(6n + 5) {
                grid-area: 2/3/4/4;
            }
            li:nth-child(6n + 6) {
                grid-area: 3/1/4/3;
            }
        }

        li {
            @extend .corner-rounded;
        }
    }

    &__image {
        @extend %alt-text-on-after;
        inline-size: 100%;
        block-size: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: inherit;
    }
}
