.breadcrumbs {
    @extend .section__wrap;
    padding-block: var(--indent-block-medium);

    &__list {
        @extend %reset-list;
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        font-size: var(--text-medium-font-size);

        &:not(:last-child) {
            &::after {
                content: " / ";
            }
        }

        &:last-child {
            color: var(--accent-color);
            pointer-events: none;
        }

        &:hover {
            color: var(--accent-color);
        }
    }

    &__link {
        color: currentColor;
        text-decoration: none;
    }
}
