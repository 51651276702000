.admin-menu {
    position: sticky;
    inset-block-start: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;

    display: flex;
    flex-direction: column;
    min-inline-size: var(--menu-width);
    max-inline-size: var(--menu-width);
    transition: width 0.5s ease;
    block-size: 100vb;

    background-color: #fff;
    box-shadow: 6px 0px 18px 0px rgba(0, 0, 0, 0.06);

    &__logo {
        flex: none;
        inline-size: var(--menu-width);
        block-size: 69px;
        border-bottom: 1px solid #ebeff2;

        text-decoration: none;
        background: url("../../images/logo.svg") no-repeat center / 90%;
    }

    &__nav {
        flex: auto;

        display: flex;
        flex-direction: column;
        gap: 2em;
        overflow: hidden;
    }

    &__list {
        flex: auto;
        @extend %reset-list;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        @include media(xs) {
            // ширина scrollbar
            &::-webkit-scrollbar {
                width: 8px;
            }
            // Цвет дорожки
            &::-webkit-scrollbar-track {
                background-color: #fff;
                border-radius: 5px;
            }
            // Плашка
            &::-webkit-scrollbar-thumb {
                background-color: var(--admin-primary-color);
                border-radius: 5px;
            }
        }
    }

    &__item {
        &_separator {
            inline-size: 100%;
            border-bottom: 1px solid #ebeff2;
            margin-block: 2em;
        }
    }
    &__link {
        text-decoration: none;

        display: flex;
        gap: 0.875em;
        align-items: center;
        padding: 0.675em 1.5em;
        cursor: pointer;
        transition: color 0.25s ease-in-out;

        &_active {
            cursor: default;
            color: var(--admin-primary-color);
        }

        &:hover {
            color: var(--admin-primary-color);
        }
    }

    &__link-icon {
        flex: none;
        @include box(1.5em);
        object-position: center;
        object-fit: contain;
        color: currentColor;
        transition: color 0.25s ease-in-out;
    }

    &__link-text {
        margin: 0;
        flex: auto;
        font-weight: 500;
        letter-spacing: 0.13px;
        transition: opacity 0.25s ease-in-out;
    }

    &__button {
        flex: none;
        min-inline-size: unset;
        border-radius: 0;
        padding-block-start: 0.25em;
        padding-block-end: 0.25em;
        .admin-button__icon {
            margin-inline-start: auto;
            @include box(2.5em);
        }
    }

    &__back {
        display: none;
    }

    @include media(sm, max) {
        position: fixed;
        inset: 0;
        @include box(100%);
        min-inline-size: unset;
        max-inline-size: unset;
        z-index: 50;

        background-color: transparent;
        box-shadow: none;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        user-select: none;
        transition:
            visibility 0s 0.35s,
            opacity 0.35s;

        .admin-menu__nav {
            width: 16em;
            background-color: #fff;
            box-shadow: 6px 0px 18px 0px rgba(0, 0, 0, 0.06);
            transition: transform 0.35s;
            transform: translateX(-120%);
        }

        .admin-menu__button {
            display: none;
        }

        .admin-menu__logo {
            inline-size: 100%;
        }

        &_opened {
            visibility: visible;
            opacity: 1;
            pointer-events: all;
            transition:
                visibility 0s,
                opacity 0.35s;

            .admin-menu__nav {
                transform: translateX(0);
            }

            .admin-menu__back {
                display: block;

                position: absolute;
                inset: 0;
                z-index: -1;
                @include box(100%);
                background-color: #00000054;
            }
        }
    }

    @include media(sm) {
        &_size_sm {
            .admin-menu__list {
                align-items: center;
            }

            .admin-menu__link {
                gap: 0;
            }

            .admin-menu__link-icon {
                @include box(2.5em);
            }

            .admin-menu__link-text {
                height: 0;
                width: 0;
                visibility: hidden;
                opacity: 0;
                pointer-events: none;
            }

            .admin-menu__button .admin-button__icon {
                transform: scale(-1, 1);
            }

            .admin-menu__logo {
                background: url("../../images/logo.svg") no-repeat center / 85%;
            }
        }

        &_size_md {
            .admin-menu__link {
                flex-direction: column;
                text-align: center;
                padding: 0.5em;
                gap: 0.25em;
            }

            .admin-menu__link-text {
                max-width: 6rem;
                font-size: 0.75em;
            }

            .admin-menu__logo {
                background: url("../../images/logo.svg") no-repeat center / 50%;
            }
        }
    }
}
