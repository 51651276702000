.to-top-button {
    cursor: pointer;
    outline: none;
    border: none;
    background: none;

    inline-size: 3.5em;
    block-size: 3.5em;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 900;
    display: flex;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 15px 1px rgba(black, 0.26);

    &:hover::after {
        background-color: var(--main-color);
    }

    &::after {
        content: "";
        display: block;
        background-color: currentColor;
        mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0iY3VycmVudENvbG9yIiB2aWV3Qm94PSIwIDAgMjQgMjQiPgogIDxwYXRoIGQ9Ik0xNi41OSAxNS40MiAxMiAxMC44M2wtNC41OSA0LjU5TDYgMTRsNi02IDYgNi0xLjQxIDEuNDJaIi8+Cjwvc3ZnPgo=");
        mask-size: contain;
        mask-repeat: no-repeat;
        inline-size: 2.875em;
        block-size: 2.875em;
        margin: auto;
    }
}
