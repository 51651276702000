.clouds-decor {
    position: relative;

    &_place_top {
        padding-block-start: var(--indent-clouds-decor);
        &::before {
            content: "";
            position: absolute;
            inset: 0 0 auto 0;
            inline-size: 100%;
            rotate: 180deg;
            block-size: clamp(1.875rem, 1.4614rem + 1.8382vw, 3.4375rem);
            background: url("../../images/clouds.svg") repeat-x center / auto 100%;
        }
    }

    &_place_bottom {
        padding-block-end: var(--indent-clouds-decor);
        &::after {
            content: "";
            position: absolute;
            inset: auto 0 0 0;
            inline-size: 100%;
            block-size: clamp(1.875rem, 1.4614rem + 1.8382vw, 3.4375rem);
            background: url("../../images/clouds.svg") repeat-x center / auto 100%;
        }
    }
}
