.advantages {
    &__color-list {
        align-items: center;

        li:nth-child(4n + 1) .advantages__color-item-icon {
            background-color: var(--main-alt-color);
        }
        li:nth-child(4n + 2) .advantages__color-item-icon {
            background-color: var(--secondary-color);
        }
        li:nth-child(4n + 3) .advantages__color-item-icon {
            background-color: var(--main-color);
        }
        li:nth-child(4n + 4) .advantages__color-item-icon {
            background-color: var(--accent-alt-color);
        }
    }

    &__color-item-icon {
        @extend .corner-rounded;
        color: #fff;
    }
}
