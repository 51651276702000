@import "../../styles/vendor/mixins";
@import "../../styles/vendor/extends";

.auth-form {
    justify-content: center;

    @include media(xs) {
        padding: 1em;
        background: url("../../images/white-and-gray-background-vector.jpg") #eee no-repeat center / cover;
        flex-direction: column;
        align-items: center;
    }

    &__logo {
        align-self: center;
        width: min(16vmax, 12em);
        text-align: center;

        img {
            display: inline;
            font-size: 0;
        }
    }

    &__title {
        font-size: 1.5em;
        margin-block: 0.75em;
        text-align: center;
    }

    &__form {
        width: min(25em, 100%);
        padding: 1.5em;
        display: flex;
        flex-direction: column;

        @include media(xs) {
            background-color: #fff;
            box-shadow:
                0 0 25px #e6e6e6,
                0 0 2px #bebebe;
            border-radius: 5px;
        }
    }

    &__footer {
        margin-block-start: auto;
        @include media(xs) {
            margin-block-start: min(6.4vh, 4em);
        }
    }

    &__info-text {
        font-size: 0.875em;
        text-align: center;
    }

    &__button {
        width: 100%;
    }
}
