@keyframes move-right-initial {
    to {
        transform: translate3d(250%, 0, 0);
    }
}

@keyframes move-right-end {
    from {
        transform: translate3d(-150%, 0, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slide-in-top {
    from {
        transform: translate(0, -1.5em);
        opacity: 0;
    }

    to {
        transform: translate(0, 0);
        opacity: 1;
    }
}

@keyframes slide-in-left {
    from {
        transform: translate(-1.5em, 0);
        opacity: 0;
    }

    to {
        transform: translate(0, 0);
        opacity: 1;
    }
}
