.not-found {
    min-height: 100vb;
    display: flex;
    padding: min(3vmax, 1em);

    &__inner {
        text-align: center;
        margin: auto;
    }

    &__title {
        margin-block: 0 var(--indent-block-large);
        font-family: var(--heading-font-family);
        font-size: var(--title-block-large-font-size);
        line-height: 1;
        font-weight: normal;
    }

    &__span-accent {
        display: block;
        font-size: clamp(80px, calc(5rem + ((1vw - 3.75px) * 5.0955)), 120px);
        text-transform: uppercase;
        color: var(--secondary-alt-color);
    }

    &__text {
        font-size: var(--text-large-font-size);
    }

    &__link {
        text-decoration: unset;
        border-block-end: 2px solid transparent;
        font-weight: 500;
        color: var(--main-color);
        text-underline-offset: 5px;
        transition: 0.15s ease-in;

        &:hover {
            border-color: currentColor;
        }
        svg {
            @include box(1em);
            vertical-align: middle;
        }
    }
}
