.header {
    background-color: white;
    box-shadow: var(--box-shadow-small);
    position: relative;
    z-index: 10;

    &__wrap {
        margin-inline: auto;
        inline-size: var(--inline-size-large);
        padding: clamp(1rem, 0.4565rem + 0.8696vw, 1.5rem) var(--inline-indent);

        display: flex;
        align-items: center;
        gap: var(--indent-2);
    }

    &__logo-link {
        flex: none;

        @include media(lg, max) {
            inline-size: clamp(55px, 8.8vw, 88px);
            block-size: clamp(44px, 7vw, 70px);
            background-image: url("../../images/logo.svg");
        }
    }

    &__menu {
        flex: auto;
    }

    &__panel {
        margin-inline-start: auto;
        flex: none;

        display: flex;
        align-items: center;
        gap: 1.25em;
    }

    &__search-button {
        min-inline-size: unset;
        border-color: var(--grey-200-color);

        &:hover {
            border-color: currentColor;
        }

        @include media(lg, max) {
            display: none;
        }
    }

    &__feedback-button {
        @include media(xl, max) {
            display: none;
        }
    }

    &__mobile-button {
        &_actived {
            color: var(--accent-color);
        }
        @include media(lg) {
            display: none;
        }
    }
}
