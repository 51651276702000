.services {
    padding-block-end: var(--indent-block-medium);
    &__content {
        display: flex;
        flex-direction: column;
        gap: var(--indent-block-large);
    }
    &__list {
        @extend %reset-list;
        display: flex;
        flex-wrap: wrap;
        gap: var(--indent-block-medium);

        li {
            flex: auto;
            @include media(sm, max) {
                flex: 1 0 6.5em;
            }
        }
    }
    &__item {
        height: 100%;
        @extend .corner-rounded;
        background-color: var(--accent-alt-color);
        padding: var(--indent-block-small);

        .text-item__text {
            flex: auto;
            text-align: center;
        }

        .text-item__icon {
            color: var(--main-alt-color);
            background-color: #fff;
            border-radius: inherit;
        }
    }
}
