.text-item {
    margin-block: 0;
    display: flex;
    align-items: center;
    gap: var(--indent-block-small);

    &__icon {
        flex: none;
        min-inline-size: clamp(3rem, 1.0978rem + 3.0435vw, 4.75rem);
        max-inline-size: clamp(3rem, 1.0978rem + 3.0435vw, 4.75rem);
        inline-size: clamp(3rem, 1.0978rem + 3.0435vw, 4.75rem);
        block-size: clamp(3rem, 1.0978rem + 3.0435vw, 4.75rem);
    }

    &__text {
        font-size: var(--text-large-font-size);
    }

    &__text-accent {
        font-weight: 600;
    }

    @include media(md, max) {
        flex-direction: column;
        text-align: center;
    }
}
