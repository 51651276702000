.menu {
    display: flex;

    @include media(md, max) {
        position: absolute;
        inset: 100% 0 auto 0;
        min-block-size: 100vh;
        min-block-size: 100dvb;
        background-color: white;
        border-block-start: 2px solid var(--grey-100-color);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);

        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        user-select: none;
        transform: translateY(-5%);
        transition:
            visibility 0s 0.35s,
            opacity 0.35s,
            transform 0.35s;

        &_opened {
            visibility: visible;
            opacity: 1;
            pointer-events: all;
            transform: translateY(0%);
            transition:
                visibility 0s,
                opacity 0.35s,
                transform 0.35s;

            li {
                animation: slide-in-top 0.35s ease-in-out backwards;
                @for $i from 1 through 5 {
                    &:nth-of-type(5n + #{$i}) {
                        animation-delay: $i * 0.03s;
                    }
                }
            }
        }
    }

    &__list {
        @extend %reset-list;

        display: flex;
        // max-inline-size: 20em;
        // overflow: hidden;
        // flex-wrap: wrap;

        & li {
            flex: none;
        }

        @include media(md, max) {
            flex-direction: column;
            margin-block: 1.5em;
        }
    }

    &__link {
        display: block;
        text-decoration: none;
        padding: 0.875em;
        font-weight: 500;
        line-height: 1.5;
        transition: 0.35s ease-in;

        &:hover {
            color: var(--accent-color);
        }

        &:active {
            transform: translateY(1px);
        }

        &_actived {
            color: var(--accent-color);
        }

        &:focus-visible {
            outline: 2px solid var(--main-text-color);
        }

        @include media(md, max) {
            padding-block: calc(0.75em / 2);
        }
    }
}
