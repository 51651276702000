*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    min-inline-size: 360px;
    margin: 0;
}

menu,
picture {
    margin: 0;
    padding: 0;
}

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

button,
a {
    color: currentColor;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    cursor: pointer;
}

button:focus,
a:focus {
    outline: none;
}

img,
iframe {
    display: block;
    max-inline-size: 100%;
}

.visually-hidden {
    position: absolute;
    inline-size: 1px;
    block-size: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    white-space: nowrap;
}

.--hide{
    display: none;
}
