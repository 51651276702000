.wave-decor {
    position: relative;

    &_place_top {
        padding-block-start: var(--indent-wave-decor);
        &::before {
            content: "";
            position: absolute;
            inset: 0 0 auto 0;
            inline-size: 100%;
            block-size: clamp(1.25rem, -0.1087rem + 2.1739vw, 2.5rem);
            background: url("../../images/wave-decor__item-1.svg") repeat-x center / auto 100%;
        }
    }

    &_place_bottom {
        padding-block-end: var(--indent-wave-decor);
        &::after {
            content: "";
            position: absolute;
            inset: auto 0 0 0;
            inline-size: 100%;
            block-size: clamp(1.25rem, -0.1087rem + 2.1739vw, 2.5rem);
            background: url("../../images/wave-decor__item-2.svg") repeat-x center / auto 100%;
        }
    }
}
