.resources {
    &__image {
        @extend .corner-rounded;
        box-shadow: var(--box-shadow-medium);
        min-block-size: clamp(6em, 15vw, 10em);
        object-fit: contain;
        object-position: center;
        transition: 0.15s ease-in;

        &:hover {
            translate: 0 -0.625em;
        }
    }
}
