.file-item {
    text-decoration: unset;
    margin-block: 0;
    display: flex;
    align-items: flex-start;
    gap: var(--indent-block-small);
    transition: 0.25s ease-in;

    &:hover {
        color: var(--accent-color);
    }

    &:active {
        transform: translateY(1px);
    }

    &:focus-visible {
        outline: 2px solid var(--main-text-color);
    }

    &__icon {
        flex: none;
        min-inline-size: clamp(3rem, 1.0978rem + 3.0435vw, 4.75rem);
        max-inline-size: clamp(3rem, 1.0978rem + 3.0435vw, 4.75rem);
        inline-size: clamp(3rem, 1.0978rem + 3.0435vw, 4.75rem);
        block-size: clamp(3rem, 1.0978rem + 3.0435vw, 4.75rem);
        color: var(--accent-color);
    }

    &__text {
        font-size: var(--text-large-font-size);
        align-self: center;
    }
}
