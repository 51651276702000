.app {
  color: var(--admin-text-color);
  font-family: var(--admin-font-family, "Roboto", sans-serif);
  display: flex;
  min-block-size: 100vb;
  background-color: #f5f6f8;

  &__header {
      flex: none;
      position: sticky;
      inset-block-start: 0;
      inset-inline-start: 0;
      inset-inline-end: 0;
      z-index: 1;
  }

  &__main {
      flex: auto;
      min-width: 0;

      display: flex;
      gap: 1.5em;
      flex-direction: column;
  }

  &__content {
      flex: auto;
      margin: 0 1.5em 1.5em;
      padding: 1em;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.06);
  }

  &__title-block {
      display: flex;
      align-items: center;
      gap: 0.5em;

      margin: 0 0 1.875rem;
  }

  &__title {
      margin: 0;
      font-size: min(3.25vmax, 1.875em);
      font-weight: 600;
  }
}