.blobs {
    position: relative;
    z-index: 1;
    overflow: hidden;

    &__item {
        position: absolute;
        z-index: -1;
        inline-size: min(45vw, 640px);
        aspect-ratio: 60/80;
        mask: url("../../images/blobs__item-1.svg") no-repeat center / contain;

        &_type_secondary {
            aspect-ratio: 75/73;
            mask: url("../../images/blobs__item-2.svg") no-repeat center / contain;
        }

        &_theme {
            &_secondary {
                background-image: linear-gradient(90deg, var(--secondary-gradient-color));
            }

            &_accent {
                background-image: linear-gradient(90deg, var(--accent-gradient-color));
            }
        }

        &_place {
            &_lead-top-left {
                inset: -16vw auto auto -11vw;
                rotate: -30deg;
            }

            &_lead-bottom-right {
                inset: auto -5vw -11vw auto;
                rotate: 10deg;
            }

            &_why-bottom-left {
                inline-size: min(35vw, 430px);
                inset: auto auto -3vw -9vw;
                rotate: 220deg;
            }

            &_why-top-right {
                inline-size: min(40vw, 490px);
                inset: -1vw -11vw auto auto;
                rotate: 62deg;
            }
        }
    }
}
