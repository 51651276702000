.footer {
    &__wrap {
        padding-block: clamp(1.5rem, -0.1304rem + 2.6087vw, 3rem);
    }

    &__logo-link {
        flex: none;
        inline-size: clamp(16.9375rem, 11.8424rem + 8.1522vw, 21.625rem);
        block-size: clamp(4rem, 2.6413rem + 2.1739vw, 5.25rem);
        margin-block-end: var(--indent-block-medium);
    }

    &__columns {
        display: flex;
        align-items: flex-start;
        gap: var(--indent-block-large);
        padding-block-end: 1.25em;
        margin-block-end: 1.25em;
        border-block-end: 2px solid var(--grey-100-color);
    }

    &__menu {
        @include media(md, max) {
            display: none;
        }
    }

    &__menu-list {
        flex-wrap: wrap;
    }

    &__address {
        flex: 1 0 min(40vw, 25em);
    }

    &__social-group {
        margin-block-start: 0.875em;
    }

    &__login-link {
        align-self: flex-start;
    }

    &__copyright {
        color: var(--main-text-low-color);

        p {
            margin-block: 1.25rem;
            font-size: var(--text-medium-font-size);
        }
    }
}
