@import "./vendor/mixins";
@import "./vendor/extends";

:root {
    --admin-text-color: #323c47;
    --admin-text-low-color: #707683;
    --admin-primary-color: rgb(16, 156, 241);
    --admin-primary-opacity-color: 16, 156, 241;

    --admin-error-color: #f7685b;
    --admin-error-opacity-color: 247, 103, 89;
    --admin-alert-color: #ffb946;
    --admin-success-color: #2ed47a;
    --admin-info-color: #109cf1;

    --admin-grey-100-color: #c2cfe0;
    --admin-grey-200-color: #90a0b7;
    --admin-grey-300-color: #707683;
    --admin-grey-400-color: #323c47;
    --admin-grey-500-color: #192a3e;

    --admin-font-family: "Roboto", sans-serif;
}
// layout
@import "./admin.blocks/app";

// UI blocks
@import "./admin.blocks/admin-button";
@import "./admin.blocks/admin-form";
@import "./admin.blocks/admin-field";
@import "./admin.blocks/admin-image-alert";
@import "./admin.blocks/admin-image-selector";

// sections
@import "./admin.blocks/admin-view-section";
@import "./admin.blocks/admin-section";
@import "./admin.blocks/admin-header";
@import "./admin.blocks/admin-menu-constructor";
@import "./admin.blocks/admin-menu";
