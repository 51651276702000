.why {
    &__card {
        @extend .bg-color_main-gradient;
        @extend .corner-rounded_size_lg;
        @extend .section__wrap;
        max-inline-size: calc(100% - 2 * var(--inline-indent));
        inline-size: clamp(46.875rem, 29.8913rem + 27.1739vw, 62.5rem);
        color: #fff;
        padding: var(--indent-block-large) var(--indent-block-xlarge);
        text-align: center;
    }

    &__list {
        @extend %reset-list;
        text-align: left;
        display: flex;
        gap: 1em;
        flex-direction: column;

        li {
            display: flex;
            align-items: flex-start;
            gap: 0.5em;
            font-size: var(--text-xlarge-font-size);
            line-height: 1.5;
            &::before {
                content: "";
                flex: none;
                inline-size: 1.5em;
                min-inline-size: 1.5em;
                max-inline-size: 1.5em;
                block-size: 1.5em;
                background: url("../../images/bullit.svg") no-repeat center / contain;
            }
        }
    }
}
