.about {
    &__two-columns {
        display: grid;
        gap: var(--indent-block-large);

        @include media(sm) {
            grid-template-columns: 0.5fr 1fr;
        }

        @include media(lg) {
            grid-template-columns: 0.4fr 0.972fr;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__image {
        @extend .corner-rounded;
        inline-size: min(17em, 100%);
        aspect-ratio: 4/5.32;
        object-fit: cover;
        object-position: center;

        @include media(lg) {
            inline-size: min(25em, 100%);
        }
    }

    &__main-info {
        font-size: var(--text-xlarge-font-size);

        p {
            margin-block: 0.5em;
        }
    }

    &__title {
        margin-block: 0 var(--indent-block-medium);
        font-size: clamp(1.25rem, 0.5707rem + 1.087vw, 1.875rem);
    }

    &__title-accent {
        display: block;
        margin-block-end: var(--indent-block-small);
        font-size: clamp(1.875rem, 1.1957rem + 1.087vw, 2.5rem);
    }

    &__main-text {
        font-size: var(--text-xlarge-font-size);
        line-height: 1.4;

        p {
            margin-block: 1em;
        }
    }
}
