@import "../../../styles/vendor/mixins";
@import "../../../styles/vendor/extends";

.profile-header {
    flex: none;
    display: flex;
    gap: 1em;
    align-items: center;
    // padding: 2.5em 1.5em;
    // max-width: calc(100% - 14vmax);

    &__inner {
        display: grid;
        gap: 0 1em;
        grid-template-columns: auto 1fr;
        cursor: pointer;
    }
    &__image {
        @extend %alt-text-on-after;
        grid-row: 1/3;
        @include box(3em);
        background-color: #f5f6f8;
        border-radius: 50%;
        object-fit: cover;
    }

    &__title {
        align-self: end;
        margin: 0;
        font-weight: 500;
        letter-spacing: 0.14px;
        @extend %cut-text;
    }

    &__subtitle {
        margin: 0;
        color: var(--admin-text-low-color);
        line-height: 150%;
        font-size: 0.75em;
        font-weight: 500;
        letter-spacing: 0.11px;
        @extend %cut-text;
    }
}
