.preloader {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
    background-color: rgba(var(--main-color-opacity), 0.8);

    &--place-page {
        z-index: 2000;
        position: fixed;
    }

    &__chasing-squares {
        width: 3.675em;
        height: 3.675em;
        line-height: 1.25em;
    }

    &__square {
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        opacity: 1;
        margin-right: .25em;
        background-color: #fff;
        animation: focus-fade 2.8s infinite;

        &:nth-child(2) {
            animation-delay: 0.7s;
            margin-right: 0;
        }

        &:nth-child(3) {
            animation-delay: 2.1s;
        }

        &:nth-child(4) {
            animation-delay: 1.4s;
            margin-right: 0;
        }
    }
}

@keyframes focus-fade {
    0% {
        opacity: 0.7;
    }

    30% {
        opacity: 0.7;
    }

    60% {
        opacity: 0;
    }

    75% {
        opacity: 0;
    }

    100% {
        opacity: 0.7;
    }
}
