%reset-list {
    padding: 0;
    margin: 0;
    list-style: none;
}

%reset-btn {
    cursor: pointer;
    outline: none;
    border: none;
    background: none;
}

%reset-input {
    appearance: none;
    outline: none;
    border: none;
    background-color: transparent;
    inline-size: 100%;
    font-family: inherit;
    font-size: 100%;
}

%reset-checkbox {
    appearance: none;
    outline: none;
}

%alt-text-on-after {
    position: relative;
    overflow: hidden;
    &:after {
        content: attr(alt);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.75em;
        padding: 0.75em;
        color: var(--dark);

        position: absolute;
        inset: 0;
        inline-size: 100%;
        block-size: 100%;
        text-align: center;
        background-image: linear-gradient(to bottom, #ccc 0%, #f1f1f1 50%, #ccc 100%);
    }
}

%hidden-block {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    user-select: none;
    transition:
        visibility 0s 0.35s,
        opacity 0.35s;
}

%visibility-block {
    visibility: visible;
    opacity: 1;
    pointer-events: all;
    transition:
        visibility 0s,
        opacity 0.35s;
}

%cut-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

%main-width {
    width: 100%;
    max-width: var(--main-width);
    margin: 0 auto;
}
