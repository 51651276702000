.tabs {
    &__list {
        @extend %reset-list;
        margin-inline: auto;
        inline-size: var(--inline-size-small);
        padding-inline: var(--inline-indent);
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: var(--indent-block-medium);

        @include media(sm) {
            flex-direction: row;
            overflow-x: auto;
        }
    }

    &__item {
        flex: auto;
        text-align: center;

        font-size: clamp(1em, 2vw, 1.25em);
        font-weight: 600;
        padding: 0.625rem 1rem;
        max-inline-size: 31.25rem;
        border-radius: 22px;
        background-color: var(--grey-100-color);
        opacity: 0.54;
        transition:
            background-color 0.25s ease-in,
            color 0.25s ease-in;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }

        &_active {
            pointer-events: none;
            opacity: 1;
            color: #fff;
            background: var(--accent-color) linear-gradient(0deg, var(--accent-gradient-color));
        }
    }

    &__section {
        padding-block: var(--indent-block-large);
    }
}
