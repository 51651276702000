.page {
    font-size: 100%;
    font-family: var(--main-font-family, sans-serif);
    font-weight: var(--main-font-weight);
    color: var(--main-text-color);
    min-block-size: 100vh;
    min-block-size: 100dvb;

    display: flex;
    flex-direction: column;

    &__content {
        flex: auto;
    }

    &__section-indent {
        padding-block: var(--indent-block-section);
    }

    &__header {
        flex: none;
    }

    &__footer {
        flex: none;
    }
}
