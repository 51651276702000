.social-group {
    @extend %reset-list;

    display: flex;
    align-items: center;
    gap: 0.75em;

    &__link-icon {
        text-decoration: none;
        display: block;
        inline-size: 1.875em;
        block-size: 1.875em;
        color: var(--main-color);
        transition: 0.25s ease-in;

        &:hover {
            transform: translateY(-1px);
        }

        &:active {
            transform: translateY(1px);
        }

        &:focus-visible {
            outline: 2px solid var(--main-text-color);
        }
    }
}
